export enum PhotoTypes {
  TODAS = "Vista general",
  RETRATO = "Retrato",
  EVENTO = "Evento",
}

export enum PortfolioParamTypes {
  PORTRAITS = "portraits",
  EVENTS = "events",
}
